import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "../../store";

const app = createApp(App);

import axiosConfig from "@/configs/axios.config";
axiosConfig(router);

import mixinsConfig from "@/configs/mixins.config";
mixinsConfig(app);

import packagesConfig from "@/configs/packages.config";
packagesConfig(app);

import { sentryInit } from "@/configs/sentry.config";
const CLIENT_URL = import.meta.env.VITE_CLIENT_URL;
sentryInit(app, router, [CLIENT_URL]);

app.use(router).use(store).mount("#app");

import { registerSW } from "virtual:pwa-register";
registerSW();

window.userSpace = "client";
