import { commonRoutes } from "@/utils/router";
export default [
  {
    path: "/contactez/:accessHash",
    name: "route.contacts.contact",
    component: () => {
      return import(/* webpackChunkName: "contacts" */ "@/vue/views/client/ContactView.vue");
    },
  },
  {
    path: "/contactez-reinitialiser-passe",
    name: "route.contact.reset.password",
    component: () => {
      return import(
        /* webpackChunkName: "contacts" */ "@/vue/views/client/ContactResetPasswordForm.vue"
      );
    },
  },
  {
    path: "/",
    name: "route.contacts",
    redirect: { name: "route.contacts.dossiersList" },
    component: () =>
      import(/* webpackChunkName: "contacts" */ "@/vue/views/common/dossiers/DossiersView.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "route.contacts.dossiersList",
        component: () =>
          import(
            /* webpackChunkName: "contacts" */ "@/vue/views/common/dossiers/DossiersListView.vue"
          ),
      },
      {
        path: "dossier/:id",
        name: "route.contacts.dossierDetails",
        component: () =>
          import(
            /* webpackChunkName: "contact-details" */ "@/vue/views/client/dossiers/ClientDossierDetailsView.vue"
          ),
        children: [
          {
            path: "notifications",
            name: "route.contacts.dossierDetails.notifications",
            component: () => {
              return import(
                /* webpackChunkName: "notifications" */ "@/vue/views/common/dossiers/DossierDetailsNotificationsView.vue"
              );
            },
            meta: { requiresAuth: true },
          },
          {
            path: "1",
            name: "route.contacts.dossierDetails.1",
            component: () =>
              import(
                /* webpackChunkName: "contact-details" */ "@/vue/views/client/dossiers/phases/ClientPhase1View.vue"
              ),
          },
          {
            path: "2",
            name: "route.contacts.dossierDetails.2",
            component: () =>
              import(
                /* webpackChunkName: "contact-details" */ "@/vue/views/client/dossiers/phases/ClientPhase2View.vue"
              ),
          },
          {
            path: "3",
            name: "route.contacts.dossierDetails.3",
            component: () =>
              import(
                /* webpackChunkName: "contact-details" */ "@/vue/views/client/dossiers/phases/ClientPhase3View.vue"
              ),
          },
          {
            path: "4",
            name: "route.contacts.dossierDetails.4",
            component: () =>
              import(
                /* webpackChunkName: "contact-details" */ "@/vue/views/client/dossiers/phases/ClientPhase4View.vue"
              ),
          },
          {
            path: "5",
            name: "route.contacts.dossierDetails.5",
            component: () =>
              import(
                /* webpackChunkName: "contact-details" */ "@/vue/views/client/dossiers/phases/ClientPhase5View.vue"
              ),
          },

          {
            path: "documents-manquants",
            name: "route.contacts.dossierDetails.invalidDocs",
            component: () =>
              import(
                /* webpackChunkName: "contact-details" */ "@/vue/views/client/dossiers/InvalidDocsView.vue"
              ),
          },
        ],
      },
    ],
  },
  ...commonRoutes({ dossiersPhaseIncluded: false }),
];
