import { createRouter, createWebHistory } from "vue-router";
import {
  requiresAuthButNotAuth,
  requiresUnauthButAuth,
  toDefaultRequiresUnauth,
  toDefaultRequiresAuth,
  toLastRoute,
  accessDenied,
  restrictClientPhaseAccess,
} from "@/utils/router";

import store from "@/store";

import routes from "./routes";

const mode = import.meta.env.MODE;

const router = createRouter({
  history: createWebHistory(mode === "development" ? "client" : "/"),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

// TODO: FIXME: client has no dossiers..
function isRouteContactsIsClientAndHasNoDossiers(to) {
  const isRouteContacts = to.name === "route.contacts";
  const clientHasDossiers = true;

  if (isRouteContacts) {
    return !clientHasDossiers;
  } else {
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  if (requiresAuthButNotAuth(to)) {
    return next(toDefaultRequiresUnauth);
  } else if (requiresUnauthButAuth(to)) {
    return next(toDefaultRequiresAuth);
  } else if (to.meta.requiresAuth) {
    if (!store.getters["auth/isClient"]) {
      accessDenied();
      // ???: ..
      store.dispatch("auth/deauthenticate");
      return next(toDefaultRequiresUnauth);
    }

    if (isRouteContactsIsClientAndHasNoDossiers(to)) {
      accessDenied();
      return next(toLastRoute(from));
    }

    if (await restrictClientPhaseAccess(to)) {
      accessDenied();
      return next(toLastRoute(from));
    }
  }

  next();
});

export default router;
